
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'BaseBtn',
    mixins: [ScreenSize],
    props: {
      button: {
        type: Object,
        required: false,
        default: () => {},
      },
      icon: {
        type: String,
        default: null,
      },
      btnColor: {
        type: String,
        default: null,
      },
      icnColor: {
        type: String,
        default: null,
      },
      btnSize: {
        type: String,
        default: null,
      },
      btnTo: {
        type: String,
        default: null,
      },
      btnHref: {
        type: String,
        default: null,
      },
      btnTarget: {
        type: String,
        default: null,
      },
      btnClass: {
        type: String,
        default: null,
      },
      btnDepressed: {
        type: Boolean,
        default: null,
      },
      btnMinWidth: {
        type: [Number, String],
        default: null,
      },
      btnTile: {
        type: Boolean,
        default: null,
      },
      btnOutlined: {
        type: Boolean,
        default: null,
      },
      btnBlock: {
        type: Boolean,
        default: null,
      },
      btnText: {
        type: Boolean,
        default: null,
      },
      mobileBlockButton: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        buttonIcon: null,
        buttonColor: null,
        iconColor: null,
        buttonSize: null,
        buttonTo: null,
        buttonClass: null,
        buttonDepressed: null,
        buttonMinWidth: null,
        buttonTile: null,
        buttonOutlined: null,
        buttonBlock: null,
        buttonText: null,
        buttonNuxt: null,
        buttonHref: null,
        buttonTarget: null,
        buttonLinkTitle: null,
      };
    },
    computed: {
      isVisible() {
        const isCorrectUser =
          (this.button.visibility_type === 'just_logged_out' &&
            !this.$auth.loggedIn) ||
          (this.button.visibility_type === 'just_logged_in' &&
            this.$auth.loggedIn) ||
          this.button.visibility_type === 'all_users' ||
          this.button.visibility_type === null;
        return isCorrectUser;
      },
    },
    watch: {
      isMobile: function () {
        if (this.btnBlock !== null) {
          this.setBlockBtn(this.btnBlock);
        } else {
          this.setBlockBtn(this.button.btn_block);
        }
      },
    },
    created() {
      if (this.button && Object.keys(this.button).length > 0) {
        this.buttonIcon = this.button.icon;
        this.buttonClass = this.button.btn_class;
        this.buttonColor = this.button.btn_color;
        this.iconColor = this.button.icon_color;
        this.buttonSize = this.button.btn_size;
        this.setBlockBtn(this.button.btn_block);
        this.buttonDepressed = this.button.btn_depressed;
        this.buttonTile = this.button.btn_tile;
        this.buttonOutlined = this.button.btn_outlined;
        this.buttonText = this.button.btn_text;
        this.buttonTo = this.button.btn_route
          ? this.localePath(this.button.btn_route)
          : null;
        this.buttonNuxt = this.button.btn_route && !this.button.btn_href;
        this.buttonTarget =
          !this.button.btn_route &&
          this.button.btn_href &&
          this.button.btn_target
            ? this.button.btn_target
            : null;

        this.buttonHref =
          !this.button.btn_route && this.button.btn_href
            ? this.button.btn_href
            : null;

        this.buttonLinkTitle = this.button.btn_link_title;
      }

      if (this.icon !== null) {
        this.buttonIcon = this.icon;
      }
      if (this.btnColor !== null) {
        this.buttonColor = this.btnColor;
      }
      if (this.icnColor !== null) {
        this.iconColor = this.icnColor;
      }
      if (this.btnSize !== null) {
        this.buttonSize = this.btnSize;
      }
      if (this.btnBlock !== null) {
        this.setBlockBtn(this.btnBlock);
      }
      if (this.btnTo !== null) {
        this.buttonTo = this.btnTo;
      }
      if (this.btnClass !== null) {
        this.buttonClass = this.btnClass;
      }
      if (this.btnDepressed !== null) {
        this.buttonDepressed = this.btnDepressed;
      }
      if (this.btnTile !== null) {
        this.buttonTile = this.btnTile;
      }
      if (this.btnTile !== null) {
        this.buttonTile = this.btnTile;
      }
      if (this.btnOutlined !== null) {
        this.buttonOutlined = this.btnOutlined;
      }
      if (this.btnBlock !== null) {
        this.buttonBlock = this.btnBlock;
      }
      if (this.btnText !== null) {
        this.buttonText = this.btnText;
      }
      if (this.btnHref !== null) {
        this.buttonHref = this.btnHref;
      }
      if (this.btnTarget !== null) {
        this.buttonTarget = this.btnTarget;
      }
      if (this.btnMinWidth !== null) {
        this.buttonMinWidth = this.btnMinWidth;
      }
    },
    methods: {
      setBlockBtn(val) {
        this.buttonBlock = this.isMobile && this.mobileBlockButton ? true : val;
      },
    },
  };
