export default {
  methods: {
    setSlides() {
      this.slides = [];
      const numSlides = Math.ceil(
        this.sliderElements.length / this.elementNumber
      );

      // Function to get a random element from an array
      const getRandomElement = (arr, exclude) => {
        let element;
        do {
          element = arr[Math.floor(Math.random() * arr.length)];
        } while (exclude.has(element));
        return element;
      };

      for (let i = 0; i < numSlides; i++) {
        const slide = [];
        const slideElementsSet = new Set(); // Set to track elements in the current slide
        for (
          let j = i * this.elementNumber;
          j < (i + 1) * this.elementNumber && j < this.sliderElements.length;
          j++
        ) {
          slide.push(this.sliderElements[j]);
          slideElementsSet.add(this.sliderElements[j]);
        }

        // If it's the last slide and it's incomplete, fill it with random elements
        if (i === numSlides - 1 && slide.length < this.elementNumber) {
          const numToAdd = this.elementNumber - slide.length;
          if (numToAdd < this.sliderElements.length) {
            for (let k = 0; k < numToAdd; k++) {
              const randomElement = getRandomElement(
                this.sliderElements,
                slideElementsSet
              );
              slide.push(randomElement);
              slideElementsSet.add(randomElement); // Add the random element to the set to avoid duplicates
            }
          }
        }

        this.slides.push(slide);
      }
    },
  },
};
