
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'SectionBaseSection',
    components: {
      CustomBigTitle: () => import('@/components/CustomBigTitle'),
      CustomSubline: () => import('@/components/CustomSubline'),
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [ScreenSize],
    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {};
    },
    computed: {
      isVisible() {
        const isCorrectDevice =
          (this.section.base_data.device_type === 'desktop' &&
            this.isDesktopOrTablet) ||
          (this.section.base_data.device_type === 'mobile' && this.isMobile) ||
          this.section.base_data.device_type === 'all' ||
          this.section.base_data.device_type === null;
        const isCorrectUser =
          (this.section.base_data.visibility_type === 'just_logged_out' &&
            !this.$auth.loggedIn) ||
          (this.section.base_data.visibility_type === 'just_logged_in' &&
            this.$auth.loggedIn) ||
          this.section.base_data.visibility_type === 'all_users' ||
          this.section.base_data.visibility_type === null;
        return isCorrectDevice && isCorrectUser;
      },
    },
    methods: {
      getSectionColor() {
        let color = 'white';
        if (this.section.base_data.background_color === 'gray') {
          color = '#f2f2f2';
        }
        return color;
      },
    },
  };
