import { render, staticRenderFns } from "./HomepageTopJobs.vue?vue&type=template&id=78c33d56&"
import script from "./HomepageTopJobs.vue?vue&type=script&lang=js&"
export * from "./HomepageTopJobs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionBaseSection: require('/usr/src/app/components/sections/BaseSection.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCarousel,VCarouselItem,VCol,VRow})
