
  import Events from '@/events/Events';
  import ScreenSize from '@/mixins/ScreenSize';
  import Carousel from '@/mixins/Carousel';
  import {shuffleArray} from '@/helper/ArrayHelper';

  export default {
    name: 'SectionHomepageTopJobs',
    components: {
      HomepageTopJobCard: () => import('@/components/HomepageTopJobCard'),
    },
    mixins: [ScreenSize, Carousel],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        sliderElements: [],
        slides: [],
        elementNumber: 0,
      };
    },
    watch: {
      '$vuetify.breakpoint.width'() {
        this.loadJobs().catch((e) => {
          this.sliderElements = [];

          throw e;
        });
        this.setElementNumber();
      },
    },
    mounted() {
      this.setElementNumber();
      this.loadJobs().catch((e) => {
        this.sliderElements = [];

        throw e;
      });
    },
    methods: {
      setElementNumber() {
        if (this.isMobile) {
          this.elementNumber = 1;
        } else {
          this.elementNumber = 2;
        }
      },
      async loadJobs() {
        // Query for single type
        const jobs = await this.$cachedStrapi
          .find('homepage-teaser-jobs')
          .catch((e) => {
            if (e.statusCode !== 404) {
              throw e;
            }
          });

        const apiGetParameters = new URLSearchParams();
        apiGetParameters.append('id_only', '1');
        apiGetParameters.append('slug_only', '1');
        apiGetParameters.append('itemsPerPage', jobs.length.toString());
        jobs.forEach((job) => {
          apiGetParameters.append('slug[]', job.job_slug);
          apiGetParameters.append('id[]', job.job_id);
        });

        const jobSlugs = await this.$store
          .dispatch('api/jobPosting/getJobPostings', apiGetParameters)
          .then((result) => {
            const jobSlugs = [];

            for (const job of result.data) {
              jobSlugs.push(job.slug);
            }

            return jobSlugs;
          });
        const filteredJobs = jobs.filter((job) =>
          jobSlugs.includes(job.job_slug)
        );

        if (!filteredJobs) {
          return;
        }

        this.sliderElements = shuffleArray(filteredJobs);
        this.setSlides();

        const items = this.sliderElements.map((item, key) => ({
          id: item.job_id,
          position: key + 1,
        }));
        this.$emitWithClientData(Events.JobBoxViewed, {
          boxType: 'HomepageTopJobs',
          items,
        });
      },
    },
  };
